<template>
  <div class="states-count-badge-wrapper" :class="region" v-if="ordersStateCount && orderStates.length > 0">
    <span class="badge" :class="'state-' + state.slug" v-tooltip.right="getStateName(state.id)" v-for="(state, i) in ordersStateCount" :key="i">
      {{ +state.count > 9 ? '9+' : state.count }}
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'StateCountBadges',
  computed: {
    ...mapGetters({
      isDashboardAdmin: 'isDashboardAdmin',
      ordersStateCount: 'ordersStateCount',
      orderStates: 'orderStates'
    })
  },
  methods: {
    ...mapActions({
      getOrdersStatesCount: 'getOrdersStatesCount'
    }),
    getStateName(id) {
      return this.orderStates.filter(state => state.id == id)[0].name;
    }
  },
  props: ['region'],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped="true"></style>
